<template>
    <div>
        <h2><router-link :to="{ name: 'trd' }">ใบกำกับภาษีแบบเต็ม</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "ออก" : "ดู"}}ใบกำกับภาษีแบบเต็ม</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <div class="pa-8">
              <v-form v-model="isFormValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="formData.order_type"
                      :items="orderType"
                      label="ประเภท *"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                      hide-details="auto"
                    >
                    </v-select>                  
                  </v-col>
                </v-row>              
                <v-row>
                  <v-col cols="12" md="6">
                    <delay-autocomplete
                      outlined
                      hide-selected                    
                      v-model="formData.order"
                      label="รายการขายที่อ้างถึง *"                    
                      no-data-text="ไม่พบข้อมูล"
                      :url="'/document/trd/orderList?type=' + formData.order_type"
                      dense
                      return-object                        
                      item-text="orderNumber"
                      item-value="id"
                      :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                      hide-details="auto"
                      :lazySearch="true"
                      ref="orderAutocomplete"
                    ></delay-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.customer_name"
                      outlined
                      label="ชื่อ *"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.customer_name, 100)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.customer_taxid"
                      outlined
                      label="เลขประจำตัวผู้เสียภาษี *"                               
                      :rules="[validators.required, validators.lengthValidator(formData.customer_taxid, 13)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.customer_address"
                      outlined
                      label="ที่อยู่ *"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.customer_address, 250)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.customer_tel"
                      outlined
                      label="เบอร์ *"                               
                      :rules="[validators.required, validators.maxLengthValidator(formData.customer_tel, 20)]"
                      hide-details="auto"          
                      dense  
                      :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>          
        </v-form>
    </div>
</template>
<script>
import { lengthValidator, maxLengthValidator, minValueValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      orderType: [
        {
          id: 'saleorder',
          name: 'รายการขายออนไลน์',
        },
        {
          id: 'posorder',
          name: 'POS',
        },
      ],
      validators: {
        required,
        maxLengthValidator,
        minValueValidator,
        lengthValidator,
      },
      formData: {
        order: null,
        order_type: 'saleorder',
        customer_name: null,
        customer_taxid: null,
        customer_address: null,
        customer_tel: null,
      },
    }
  },
  async created() {
    const trd_id = this.$route.params.trd_id

    if (trd_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Document'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/document/trd/' + trd_id)

      this.formData = {
        ...response,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  mounted() {
    if (!this.$route.query.order_id || !this.$route.query.orderNumber || !this.$route.query.order_type) return

    this.formData.order_type = this.$route.query.order_type

    this.formData.order = {
      id: this.$route.query.order_id,
      orderNumber: this.$route.query.orderNumber,
    }
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const trd_id = this.$route.params.trd_id

        const sumbitData = {
          ...this.formData,
          order_id: this.formData.order.id,
        }

        if (trd_id == 'create') {
          const response = await asyncPost('/document/trd', sumbitData)
          this.$router.push({ name: 'trd', query: { trdId: response.id } })
        } else {
          await asyncUpdate('/document/trd/' + trd_id, this.formData)

          this.$router.push({ name: 'trd' })
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>